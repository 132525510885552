<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="financial_account_id" label="财务账户">
          <a-select style="width:260px;" placeholder="请选择账户" v-model="financial_account_id">
            <a-select-option v-for="(item, index) in financeAccountList"
                 :value="item.financial_account_id"
                 :key="index">{{item.financial_account_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="search_date" label="日期范围">
          <a-range-picker style="width:300px"
            v-model="search_date" valueFormat="YYYY-MM-DD"/>
        </a-form-model-item>
      </a-form-model>
    </div>


    <div>
      <div class="mt-6">
        <a-button style="min-width:180px;" type="primary" @click="handlerExport('all')">导出 账户的全部流水</a-button>
        <span class="btn-tip">* 支付到本财务账户的线上订单流水&线下核销流水，以及本财务账户的钱包流水，按费用发生时间计算</span>
      </div>
      <div class="mt-6" v-if="financial_account_id != 1">
        <a-button style="min-width:180px;" type="primary" @click="handlerExport('keji_guazhang')">导出 科技公司挂账的订单流水</a-button>
        <span class="btn-tip">* 分配给本财务的科技公司挂账订单的订单流水，按费用发生时间计算</span>
      </div>
      <div class="mt-6">
        <a-button class="p-0" type="link" @click="handlerExport('order')">导出 线上订单流水明细</a-button>
        <span class="btn-tip">* 支付到本财务账户的线上订单的流水（用户消费支付、退款等），按费用发生时间计算</span>
      </div>
      <div class="mt-6">
        <a-button class="p-0" type="link" @click="handlerExport('vipcard')">导出 钱包/礼品卡明细</a-button>
        <span class="btn-tip">* 充值到本财务账户的钱包以及支付到本财务账户的礼品卡 的流水记录（钱包充值/礼品卡购买、消费、退款等），按费用发生时间计算</span>
      </div>
    </div>
		
  </div>
</template>

<script>
import { 
	financeAccountExportAllFlow,
	financeAccountExportOrderFlow, 
	financeAccountExporKjgzFlow,
	financeAccountExportVipCardFlow, 
} from "@/api/finance/finance.js"

export default {
  components: {  },
  data() {
    return {
			search_date: [],
			financial_account_id: '',
			financeAccountList: [],
    }
  },
  mounted() {
    this.search_date = [
			this.getThisMonthFirstDay(),
			this.getToday(),
		]

    const list = this.$store.getters['authAccountList']
		if(list && list.length) {
			this.financeAccountList = list
			this.financial_account_id = list[0].financial_account_id
		}
  },
  methods: {
    async handlerExport(type) {
			if(this.search_date.length < 2){
				this.$message.warning("请选择时间")
				return
			}
			if(new Date(this.search_date[1]).getTime() - new Date(this.search_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("完成时间跨度不能超一个月")
					return
				}
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}

			// 新窗口打开页面
			if(type == "all") financeAccountExportAllFlow(params)
			if(type == "order") financeAccountExportOrderFlow(params)
			if(type == "vipcard") financeAccountExportVipCardFlow(params)
			if(type == "keji_guazhang") financeAccountExporKjgzFlow(params)
		},

		// -----------
		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
.btn-tip{
	margin-left: 20px;
	font-size: 12px;
	color: #999;
}
</style>